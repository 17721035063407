export const TYPE_FETCH_CONFIRMATION_GENERATED_CARDS = "TYPE_FETCH_CONFIRMATION_GENERATED_CARDS";
export const TYPE_FETCH_CONFIRMATION_GENERATED_CARDS_SUCCESS = "TYPE_FETCH_CONFIRMATION_GENERATED_CARDS_SUCCESS";
export const TYPE_FETCH_CONFIRMATION_GENERATED_CARDS_FAILURE = "TYPE_FETCH_CONFIRMATION_GENERATED_CARDS_FAILURE";
export const TYPE_CONFIRM_RECEPTION = "TYPE_CONFIRM_RECEPTION";
export const TYPE_CONFIRM_RECEPTION_SUCCESS = "TYPE_CONFIRM_RECEPTION_SUCCESS";
export const TYPE_CONFIRM_RECEPTION_FAILURE = "TYPE_CONFIRM_RECEPTION_FAILURE";
export const TYPE_REJECT_RECEPTION = "TYPE_REJECT_RECEPTION";
export const TYPE_REJECT_RECEPTION_SUCCESS = "TYPE_REJECT_RECEPTION_SUCCESS";
export const TYPE_REJECT_RECEPTION_FAILURE = "TYPE_REJECT_RECEPTION_FAILURE";
export const TYPE_GET_CARD_DETAILS = "TYPE_GET_CARD_DETAILS";
export const TYPE_GET_CARD_DETAILS_SUCCESS = "TYPE_GET_CARD_DETAILS_SUCCESS";
export const TYPE_GET_CARD_DETAILS_FAILURE = "TYPE_GET_CARD_DETAILS_FAILURE";
