export enum Locations {
	HOME = "/",
	LOGIN = "/login",
	MANAGE_RECEPTIONS = "/manage-receptions",
	CONFIRM_RECEPTION = "/confirm-reception",
	REJECT_RECEPTION = "/reject-reception",
	SELECT_PLACE_OF_BUSINESS = "/select-place-of-business",
	REGISTER = "/register",
	SETTINGS = "/settings",
	UPDATE_TOKENS = "/update-tokens",
	ACTIVE_CARGOS = "/active-cargos",
	REJECTED_CARGOS = "/rejected-cargos",
	SELECT_CARGO = "/select-cargo",
	CARGO_CONFIRMATION = "/cargo-confirmation",
	ACCOUNT_EXPIRED = "/account-expired",
	ACCOUNT_BLOCKED = "/account-blocked",
	END_TRANSPORT = "/end-transport",
	REDIRECT = "/redirect",
}
