import React from "react";
import {get as lodashGet} from "lodash";
import {Form} from "react-final-form";
import {Button} from "../../../../components/button/button";
import {globalStyles} from "../../../../css/global.styles";
import {IRejectReceptionFormValues} from "../../interfaces/forms/rejectReception";
import {Textarea} from "../../../../components/textarea/textarea";
import {Redirect, useHistory} from "react-router-dom";
import {Header} from "../../../../components/header/header.component";
import {Locations} from "../../../../constants/locations";
import {Container} from "../../../../components/container/container.component";
import {FormFieldWrapper} from "../../../../components/formFieldWrapper/formFieldWrapper.component";
import {ErrorMessage} from "../../../../components/errorMessage";

export function RejectReceptionComponent(props) {
	const {rejectReception, exchangedData, error, updateFormData, apiType} = props;
	const globalClasses = globalStyles({});

	let history = useHistory();

	const handleGoBack = () => {
		updateFormData({
			from: Locations.REJECT_RECEPTION,
			type: "goBack",
			formValues: lodashGet(exchangedData, 'formValues'),
		});
		history.push(Locations.MANAGE_RECEPTIONS);
	};

	const initialValues: IRejectReceptionFormValues = {
		remarks: "",
	};

	const handleValidation = (values: IRejectReceptionFormValues) => {
		const errors: any = {};

		if (!values.remarks) {
			errors.remarks = "To pole jest wymagane";
		}

		return errors;
	};

	const onSubmit = async (values: IRejectReceptionFormValues) => {
		rejectReception(apiType, lodashGet(exchangedData, "formValues.cardId"), values.remarks);
	};

	if (!Object.keys(lodashGet(exchangedData, "formValues", {})).length) {
		return (
			<Redirect
				to={{
					pathname: Locations.MANAGE_RECEPTIONS
				}}
			/>
		);
	} else {
		return (
			<Container>
				<Header>
					Odrzucenie przyjęcia
				</Header>
				<Form
					onSubmit={onSubmit}
					validate={handleValidation}
					initialValues={initialValues}
					render={({ handleSubmit, form, submitting, pristine, values }) => (
						<form onSubmit={handleSubmit} noValidate={true}>
							<FormFieldWrapper>
								<Textarea
									required={true}
									label="Powód odrzucenia karty"
									name="remarks"
									className={globalClasses.inputWidth}
								/>
							</FormFieldWrapper>
							<FormFieldWrapper alignContent="right">
								<Button onClick={handleGoBack} className={globalClasses.centerButton} variant="contained" color="secondary">
									Cofnij
								</Button>
								<Button className={globalClasses.centerButton} variant="contained" color="primary" type="submit" disabled={Boolean(Object.keys(handleValidation(values)).length)}>
									Odrzuć przyjęcie
								</Button>
							</FormFieldWrapper>
							<ErrorMessage errors={error} type="general" />
						</form>
					)}
				/>
			</Container>
		);
	}
}



