import {getDefaultFailureActionReducer} from "./defaultFailureAction";

export function getGetUserFailureReducer(state, action) {
	return {
		...state,
		isLoading: action.isLoading,
		isFetchingAccountData: false,
		...getDefaultFailureActionReducer(state, action),
	};
}
