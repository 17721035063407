import {makeStyles} from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
	root: {
		color: "black",
		padding: "10px 0",
		fontSize: "12px",
		fontFamily: theme.typography.fontFamily,
	},
	error: {
		color: theme.palette.error.main,
		textAlign: "right",
	}
}));

