import React, {useEffect} from "react";
import {get as lodashGet} from "lodash";
import {Form} from "react-final-form";
import {Textbox} from "../../components/textbox/textbox";
import {Button} from "../../components/button/button";
import {ErrorMessage} from "../../components/errorMessage";
import {useHistory, useLocation} from "react-router-dom";
import {ILoginFormValues} from "../../interfaces/forms/login";
import {useStyles} from "./login.styles";
import {globalStyles} from "../../css/global.styles";
import {Header} from "../../components/header/header.component";
import {Container} from "../../components/container/container.component";
import {FormFieldWrapper} from "../../components/formFieldWrapper/formFieldWrapper.component";
import {Locations} from "../../constants/locations";
import {ButtonText} from "../../components/buttonText";
import {containsSpaces} from "../../helpers/validation";

export function LoginComponent(props) {
	const {login, getUser, error, updateFormData, accessToken, roleType, exchangedData} = props;
	const classes = useStyles({});
	const globalClasses = globalStyles({});

	let history = useHistory();
	let location: any = useLocation();

	const preselectedLogin = lodashGet(exchangedData, "login", "");

	let { from } = location.state || { from: { pathname: "/" } };

	const initialValues: ILoginFormValues = {
		cardId: "",
		login: preselectedLogin,
		password: "",
	};

	useEffect(() => {
		if (accessToken) {
			getUser(accessToken.decoded.sub, from.pathname);
		}
	}, [accessToken]);

	const onSubmit = async (values: ILoginFormValues) => {
		updateFormData({
			from: Locations.LOGIN,
			formValues: values,
		});

		login(roleType, values);
	};

	const handleRegisterButtonClick = () => {
		history.push(Locations.REGISTER);
	};

	const handleValidation = (values: ILoginFormValues) => {
		const errors: any = {};

		if (!values.login) {
			errors.login = "To pole jest wymagane";
		}

		if (containsSpaces(values.login)) {
			errors.login = "Nazwa użytkownika nie może zawierac spacji";
		}

		if (!values.password) {
			errors.password = "To pole jest wymagane";
		}

		if (containsSpaces(values.password)) {
			errors.password = "Hasło nie może zawierac spacji";
		}

		return errors;
	};

	return (
		<Container>
			<Header>
				Logowanie
			</Header>
			<Form
				validate={handleValidation}
				onSubmit={onSubmit}
				initialValues={initialValues}
				render={({ handleSubmit, form, submitting, pristine, values }) => {
					return (
						<form onSubmit={handleSubmit} noValidate={true}>
							<FormFieldWrapper>
								<Textbox
									required={true}
									name="login"
									label="Nazwa użytkownika"
									className={globalClasses.inputWidth}
									serverErrors={error}
								/>
							</FormFieldWrapper>
							<FormFieldWrapper>
								<Textbox
									required={true}
									name="password"
									type="password"
									label="Hasło"
									className={globalClasses.inputWidth}
								/>
							</FormFieldWrapper>
							<FormFieldWrapper alignContent="right">
								<ButtonText className={classes.registerButton} onClick={handleRegisterButtonClick}>Zarejestruj się</ButtonText>
								<Button variant="contained" color="primary" type="submit" disabled={Boolean(Object.keys(handleValidation(values)).length)}>Zaloguj</Button>
							</FormFieldWrapper>

							<ErrorMessage errors={error} type="general" />
						</form>
					);
				}}
			/>
		</Container>
	);
}



