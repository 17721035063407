import * as React from "react";
import {useStyles} from "../../card.styles";
import {ICard} from "../../../../interfaces/getApprovedCardsResponse";
import {CardSubTypes} from "../../../../constants/cardSubTypes";

export function CardSubType(props) {
	const {card}: {card: ICard} = props;
	const classes = useStyles({});

	if (card.cardSubtype) {
		return (
			<div className={classes.row}>
				<div className={classes.col1}>Typ karty</div>
				<div className={classes.col2}>{card.cardSubtype === CardSubTypes.RECEIVE ? "Odbiór odpadów od właściciela nieruchomości" : (card.cardSubtype === CardSubTypes.TRANSFER ? "Przekazanie odpadów" : "")}</div>
			</div>
		);
	} else {
		return null;
	}
}

