export function getRegisterSuccessReducer(state, action) {
	return {
		...state,
		isLoading: action.isLoading,
		isDisconnected: false,
		isLoggedIn: false,
		locationToChangeTo: action.locationToChangeTo,
		error: null,
	};
}
