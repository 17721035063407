import {makeStyles} from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
	root: {
		position: "relative",
		overflow: "hidden",
		backgroundColor: "white",
		borderBottom: "4px solid #1a6c40",
		borderLeft: "4px solid #1a6c40",
		borderRight: "4px solid #1a6c40",
		"&:not(:last-child)": {
			marginBottom: "15px",
		}
	},
	highlight: {
		borderLeft: "4px solid #fff200",
		borderRight: "4px solid #fff200",
		borderBottom: "4px solid #fff200",
	},
	error: {
		borderLeft: "4px solid #e30613",
		borderRight: "4px solid #e30613",
		borderBottom: "4px solid #e30613",
	},
	reciever: {
		borderLeft: "4px solid #005b7f",
		borderRight: "4px solid #005b7f",
		borderBottom: "4px solid #005b7f",
	}
}));






