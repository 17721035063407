import axios from "axios";
import {CardTypes} from "../../../constants/cardTypes";
import Cookies from "js-cookie";
import {getServerUrl} from "../../../helpers/server";

export function getCards(apiType, cardType) {
	return axios({
		method: 'get',
		url: `${getServerUrl()}/${apiType}/receiver/card?status=${cardType}`,
		headers: {
			"Content-Type": "application/json",
			"Authorization": "Bearer " + Cookies.get("mbdo_at"),
		},
	});
}

export function getConfirmationGeneratedCards(apiType) {
	return getCards(apiType, CardTypes.CONFIRMATION_GENERATED);
}

export function confirmReception(apiType, cardId, correctedWasteMass, remarks) {
	return axios({
		method: 'put',
		url: `${getServerUrl()}/${apiType}/receiver/receiveconfirmation`,
		data: {
			cardId,
			correctedWasteMass,
			remarks,
		},
		headers: {
			"Content-Type": "application/json",
			"Authorization": "Bearer " + Cookies.get("mbdo_at"),
		},
	});
}

export function rejectReception(apiType, cardId, remarks) {
	return axios({
		method: 'put',
		url: `${getServerUrl()}/${apiType}/receiver/reject`,
		data: {
			cardId,
			remarks,
		},
		headers: {
			"Content-Type": "application/json",
			"Authorization": "Bearer " + Cookies.get("mbdo_at"),
		},
	});
}

export function getCardDetails(apiType, cardId, cardType) {
	// const wrongTypeString = cardType === CardTypes.APPROVED ? "statusConfirmationGenerated" : "";

	return axios({
		method: 'get',
		url: `${getServerUrl()}/${apiType}/receiver/card/${cardId}/statusConfirmationGenerated`,
		headers: {
			"Content-Type": "application/json",
			"Authorization": "Bearer " + Cookies.get("mbdo_at"),
		},
	});
}
