import {rejectReception as rejectReceptionAPI} from "../../api/api";
import {TYPE_REJECT_RECEPTION, TYPE_REJECT_RECEPTION_FAILURE, TYPE_REJECT_RECEPTION_SUCCESS} from "./types";
import {toast} from "react-toastify";
import {getConfiguration} from "../../../../helpers/toast";

export function rejectReception(apiType, cardId, remarks) {
	return function(dispatch) {
		dispatch(rejectReceptionAction());

		return rejectReceptionAPI(apiType, cardId, remarks).then((response) => {
			dispatch(rejectReceptionSuccessAction(response));
		}).catch((error) => {
			dispatch(rejectReceptionFailureAction(error));
		});
	};
}

export function rejectReceptionAction() {
	return {
		type: TYPE_REJECT_RECEPTION,
		isLoading: true,
	};
}

export function rejectReceptionSuccessAction(response) {
	toast.success("Przejęcie zostało odrzucone", getConfiguration());

	return {
		type: TYPE_REJECT_RECEPTION_SUCCESS,
		isLoading: false,
		response: response,
	};
}

export function rejectReceptionFailureAction(error) {
	return {
		type: TYPE_REJECT_RECEPTION_FAILURE,
		isLoading: false,
		error,
	};
}
