import {getErrorHandling} from "../../../../helpers/errors";

export function getCardDetailsReducer(state, action) {
	return {
		...state,
		error: null,
		isFetchingCardDetails: {
			...state.isFetchingCardDetails,
			[action.cardId]: true
		},
	};
}

export function getCardDetailsSuccessReducer(state, action) {
	const cardDetails = action.response.data;

	return {
		...state,
		isFetchingCardDetails: {
			...state.isFetchingCardDetails,
			[action.cardId]: false
		},
		isDisconnected: false,
		cardsDetails: {
			...state.cardsDetails,
			[cardDetails.kpoId || cardDetails.kpokId]: cardDetails,
		},
		error: null,
	};
}

export function getCardDetailsFailureReducer(state, action) {
	return {
		...state,
		isFetchingCardDetails: {
			...state.isFetchingCardDetails,
			[action.cardId]: false
		},
		...getErrorHandling(action, state),
	};
}
