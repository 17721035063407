import {connect} from "react-redux";
import {ConfirmReceptionComponent} from "./confirmReception.component";
import {getApiType, getFormData, getError} from "../../../../store/selectors/app.selectors";
import {getCardsDetails, isFetchingCardDetails} from "../../store/selectors/app.selectors";
import {confirmReception, getCardDetails} from "../../store/actions";
import {updateFormDataAction} from "../../../../store/actions";

const mapStateToProps = (state) => {
	return {
		exchangedData: getFormData(state),
		error: getError(state),
		apiType: getApiType(state),
		isFetchingCardDetails: isFetchingCardDetails(state),
		cardsDetails: getCardsDetails(state),
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		confirmReception: (apiType, cardId, correctedWasteMass, remarks) => {dispatch(confirmReception(apiType, cardId, correctedWasteMass, remarks));},
		updateFormData: (exchangedData) => {dispatch(updateFormDataAction(exchangedData));},
		getCardDetails: (apiType, cardId, cardType) => {dispatch(getCardDetails(apiType, cardId, cardType));},
	};
};

export const ConfirmReceptionRoute = connect(
	mapStateToProps,
	mapDispatchToProps
)(ConfirmReceptionComponent);
