import {Button as ButtonMaterialUi} from "@material-ui/core";
import * as React from "react";

export function Button(props) {
	const {children, ...rest} = props;

	return (
		<ButtonMaterialUi {...rest}>{children}</ButtonMaterialUi>
	);
}

