import {getConfirmationGeneratedCards as getConfirmationGeneratedCardsAPI} from "../../api/api";
import {
	TYPE_FETCH_CONFIRMATION_GENERATED_CARDS,
	TYPE_FETCH_CONFIRMATION_GENERATED_CARDS_FAILURE,
	TYPE_FETCH_CONFIRMATION_GENERATED_CARDS_SUCCESS
} from "./types";

export function fetchConfirmationGeneratedCards(apiType) {
	return function(dispatch) {
		dispatch(fetchConfirmationGeneratedCardsAction());

		return getConfirmationGeneratedCardsAPI(apiType).then((response) => {
			dispatch(fetchConfirmationGeneratedCardsSuccessAction(response));
		}).catch((error) => {
			dispatch(fetchConfirmationGeneratedCardsFailureAction(error));
		});
	};
}

export function fetchConfirmationGeneratedCardsAction() {
	return {
		type: TYPE_FETCH_CONFIRMATION_GENERATED_CARDS,
		isLoading: true,
	};
}

export function fetchConfirmationGeneratedCardsSuccessAction(response) {
	return {
		type: TYPE_FETCH_CONFIRMATION_GENERATED_CARDS_SUCCESS,
		isLoading: false,
		response: response,
	};
}

export function fetchConfirmationGeneratedCardsFailureAction(error) {
	return {
		type: TYPE_FETCH_CONFIRMATION_GENERATED_CARDS_FAILURE,
		isLoading: false,
		error,
	};
}
