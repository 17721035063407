import {makeStyles} from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
	root: {
		color: "black",
		padding: "10px",
		fontSize: "13px",
		fontFamily: theme.typography.fontFamily,
		borderRadius: "3px",
		border: "1px solid rgba(26, 108, 64, 1)",
		backgroundColor: "rgba(26, 108, 64, 0.4)",
		textAlign: "center",
		margin: "5px 0",
		display: "flex",
	},
	iconWrapper: {
		display: "flex",
		justifyContent: "center",
		alignItems: "center",
		paddingRight: "10px",
	},
	textWrapper: {
		display: "flex",
		justifyContent: "center",
		alignItems: "center",
	},
	typeInfo: {
		border: "1px solid rgba(172, 172, 172, 1)",
		backgroundColor: "rgba(225, 225, 225, 1)",
	},
	typeSuccess: {
		border: "1px solid rgba(26, 108, 64, 1)",
		backgroundColor: "rgba(26, 108, 64, 0.4)",
	},
	typeHighlight: {
		border: "1px solid rgba(255, 242, 0, 1)",
		backgroundColor: "rgba(255, 242, 0, 0.4)",
	},
	typeError: {
		border: "1px solid rgba(227, 6, 19, 0.5)",
		backgroundColor: "rgba(227, 6, 19, 0.2)",
	},
	iconInfo: {
		color: "#898989"
	},
	iconSuccess: {
		color: "#005826"
	}
}));
