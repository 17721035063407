import React from "react";
// import {useStyles} from "./accountExpired.styles";
import {Header} from "../../components/header/header.component";
import {Message} from "../../components/message";
import {Container} from "../../components/container/container.component";
import {getFormattedDateTime} from "../../helpers/datetime";
import {useHistory, useLocation} from "react-router-dom";
import {getExpirationDate} from "../../helpers/account";

export function AccountExpiredComponent(props) {
	const {accountData} = props;
	const expirationDate = getExpirationDate(accountData);
	//const classes = useStyles({});

	/* eslint-disable */
	let history = useHistory();
	let location: any = useLocation();

	let { from } = location.state || { from: { pathname: "/" } };
	/* eslint-enable */

	if (!expirationDate) {
		return <div></div>;
	}

	return (
		<Container>
			<Header>
				Twoje konto jest nieaktywne
			</Header>
			<Message>
				Twoje konto straciło ważność <b>{getFormattedDateTime(expirationDate)}</b>. Prosimy i opłacenie abonamentu na kolejny miesiąc którą otrzymali Państwo na adres e-mail przypisany do konta.
				<br /><br />
				W razie pytań lub wątpliwości prosimy o kontakt pod numerem 518 071 101.
			</Message>
		</Container>
	);
}



