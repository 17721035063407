import {connect} from "react-redux";
import {PasswordComponent} from "./password.component";
import {
	getAccessToken,
	getError,
	getRefreshCurrentPageStatus,
} from "../../../../store/selectors/app.selectors";
import {ISettingsPasswordFormValues} from "../../../../interfaces/forms/settingsPassword";
import {changePassword, clearRefreshCurrentPageAction} from "../../../../store/actions";

const mapStateToProps = (state) => {
	return {
		error: getError(state),
		refreshCurrentPageStatus: getRefreshCurrentPageStatus(state),
		accessToken: getAccessToken(state),
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		changePassword: (userId, formValues: ISettingsPasswordFormValues) => {dispatch(changePassword(userId, formValues));},
		clearRefreshCurrentPage: () => {dispatch(clearRefreshCurrentPageAction());}
	};
};

export const PasswordSection = connect(
	mapStateToProps,
	mapDispatchToProps
)(PasswordComponent);
