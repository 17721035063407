import {makeStyles} from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
	root: {

	},
	cardSelect: {
		width: "100%",
	},
	cardSelectSubText: {
		fontSize: "12px",
		color: "rgba(0, 0, 0, 0.54)",
	},
	nameContainer: {
		display: "flex",
	},
	senderContainer: {
		flexGrow: 1,
		whiteSpace: "nowrap",
		textOverflow: "ellipsis",
		overflow: "hidden",
	},
	receiverContainer: {
		flexGrow: 1,
		whiteSpace: "nowrap",
		textOverflow: "ellipsis",
		overflow: "hidden"
	},
	separatorContainer: {
		flexGrow: 1,
	},
	separatorIcon: {
		fontSize: "22px",
	}

}));






