import {getCardDetails as getCardDetailsAPI} from "../../api/api";
import {TYPE_GET_CARD_DETAILS, TYPE_GET_CARD_DETAILS_FAILURE, TYPE_GET_CARD_DETAILS_SUCCESS} from "./types";

export function getCardDetails(apiType, cardId: string, cardType) {
	return function(dispatch) {
		dispatch(getCardDetailsAction(cardId));

		return getCardDetailsAPI(apiType, cardId, cardType).then((response) => {
			dispatch(getCardDetailsSuccessAction(response, cardId));
		}).catch((error) => {
			dispatch(getCardDetailsFailureAction(error, cardId));
		});
	};
}

export function getCardDetailsAction(cardId) {
	return {
		type: TYPE_GET_CARD_DETAILS,
		cardId
	};
}
export function getCardDetailsSuccessAction(response, cardId) {
	return {
		type: TYPE_GET_CARD_DETAILS_SUCCESS,
		response: response,
		cardId
	};
}

export function getCardDetailsFailureAction(error, cardId) {
	return {
		type: TYPE_GET_CARD_DETAILS_FAILURE,
		error,
		cardId
	};
}
