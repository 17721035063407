import React from "react";
import {Form} from "react-final-form";
import {Button} from "../../../../components/button/button";
import {ISettingsRegistrationNumberFormValues} from "../../../../interfaces/forms/settingsRegistrationNumber";
import {useStyles} from "./registrationNumber.styles";
import {globalStyles} from "../../../../css/global.styles";
import {FormFieldWrapper} from "../../../../components/formFieldWrapper/formFieldWrapper.component";
import {useHistory, useLocation} from "react-router-dom";
import {Textbox} from "../../../../components/textbox/textbox";
import {Paragraph} from "../../../../components/paragraph";
import Cookies from "js-cookie";
import {toast} from "react-toastify";
import {getConfigurationWithClosingButton} from "../../../../helpers/toast";

export function RegistrationNumberSectionComponent(props) {
	const classes = useStyles({});
	const globalClasses = globalStyles({});

	/* eslint-disable */
	let history = useHistory();
	let location: any = useLocation();

	let { from } = location.state || { from: { pathname: "/" } };
	/* eslint-enable */

	const initialValues: ISettingsRegistrationNumberFormValues = {
		vehicleRegNumber: Cookies.get("mbdo_vehicleRegNumberCustomPhrase") || "",
	};

	const onSubmit = async (values: ISettingsRegistrationNumberFormValues) => {
		Cookies.set("mbdo_vehicleRegNumberCustomPhrase", values.vehicleRegNumber || "");
		Cookies.set("mbdo_vehicleHasRegNumberCustomPhrase", Boolean(values.vehicleRegNumber));

		toast.success("Zmiany zostały poprawnie zapisane.", getConfigurationWithClosingButton());
	};

	const handleValidation = (values: ISettingsRegistrationNumberFormValues) => {
		const errors: any = {};

		// if (!values.clientId) {
		// 	errors.clientId = "To pole jest wymagane";
		// }

		return errors;
	};

	return (
		<>
			<Form
				validate={handleValidation}
				onSubmit={onSubmit}
				initialValues={initialValues}
				render={({ handleSubmit, form, submitting, pristine, values }) => {
					return (
						<form onSubmit={handleSubmit} className={classes.form}>
							<Paragraph>W pole poniżej wpisz frazę po której wyszukiwane będą karty. Może to być np. numer rejestracyjny pojazdu.</Paragraph>
							<FormFieldWrapper>
								<Textbox
									required={false}
									name="vehicleRegNumber"
									label="Szukana fraza"
									className={globalClasses.inputWidth}
								/>
							</FormFieldWrapper>
							<FormFieldWrapper alignContent="right">
								<Button variant="contained" color="primary" type="submit" disabled={Boolean(Object.keys(handleValidation(values)).length)}>Zapisz zmiany</Button>
							</FormFieldWrapper>
						</form>
					);
				}}
			/>
		</>
	);
}
