import * as React from "react";
import {useStyles} from "./cardMenuItemContent.styles";
import {getFormattedDateTime} from "../../helpers/datetime";
import ArrowRightAltIcon from "@material-ui/icons/ArrowRightAlt";

export function CardMenuItemContent(props) {
	const {card/*, apiType*/} = props;
	const classes = useStyles({});

	return (
		<div className={classes.cardSelect}>
			<div className={classes.nameContainer}>
				<div className={classes.senderContainer}>{card.senderName+"fdfdfddfs fda sfdf"}</div>
				<div className={classes.separatorContainer}><ArrowRightAltIcon className={classes.separatorIcon} /></div>
				<div className={classes.receiverContainer}>{card.receiverName+"fsdf saf asdfs"}</div>
			</div>
			<div className={classes.cardSelectSubText}>Data transportu: {getFormattedDateTime(card.realTransportTime)}</div>
		</div>
	);
}

