import {connect} from "react-redux";
import {CardDetailsReceiver as CardDetailsReceiverComponent} from "../../../../components/cardDetailsReceiver/cardDetailsReceiver.component";
import {getCardDetails} from "../../store/actions";
import {getApiType} from "../../../../store/selectors/app.selectors";
import {getCardsDetails, isFetchingCardDetails} from "../../store/selectors/app.selectors";

const mapStateToProps = (state) => {
	return {
		apiType: getApiType(state),
		isFetchingCardDetails: isFetchingCardDetails(state),
		cardsDetails: getCardsDetails(state),
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		getCardDetails: (apiType, cardId, cardType) => {dispatch(getCardDetails(apiType, cardId, cardType));},
	};
};

export const CardDetailsReceiver = connect(
	mapStateToProps,
	mapDispatchToProps
)(CardDetailsReceiverComponent);
