import * as React from "react";
import {useStyles} from "./formFieldWrapper.styles";
import classnames from "classnames";

export function FormFieldWrapper(props) {
	const {children, alignContent, hide} = props;
	const classes = useStyles({});

	return (
		<div className={classnames(classes.root, {[classes.hide]: Boolean(hide)}, {[classes.alignContentRight]: alignContent === "right"}, {[classes.alignContentLeft]: alignContent === "left"}, {[classes.alignContentCenter]: alignContent === "center"})}>{children}</div>
	);
}

