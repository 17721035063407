import {connect} from "react-redux";
import {AccountExpiredComponent} from "./accountExpired.component";
import {getAccountData, getError} from "../../store/selectors/app.selectors";

const mapStateToProps = (state) => {
	return {
		error: getError(state),
		accountData: getAccountData(state),
	};
};

const mapDispatchToProps = (dispatch) => {
	return {

	};
};

export const AccountExpiredRoute = connect(
	mapStateToProps,
	mapDispatchToProps
)(AccountExpiredComponent);
