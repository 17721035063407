import * as React from "react";
import {useStyles} from "./container.styles";
import classnames from "classnames";

export function Container(props) {
	const {children, classNames = {}} = props;
	const classes = useStyles({});

	return (
		<div className={classnames(classes.root, classNames.root)}>{children}</div>
	);
}

