import {changePassword as changePasswordAPI} from "../../api/api";
import {TYPE_CHANGE_PASSWORD, TYPE_CHANGE_PASSWORD_SUCCESS, TYPE_CHANGE_PASSWORD_FAILURE} from "./types";
import {ISettingsPasswordFormValues} from "../../interfaces/forms/settingsPassword";
import {toast} from "react-toastify";
import {getConfiguration} from "../../helpers/toast";

export function changePassword(userId, formValues: ISettingsPasswordFormValues) {
	return function(dispatch) {
		dispatch(changePasswordAction());

		return changePasswordAPI(userId, formValues).then((response) => {
			dispatch(changePasswordSuccessAction(response));
		}).catch((error) => {
			dispatch(changePasswordFailureAction(error));
		});
	};
}

export function changePasswordAction() {
	return {
		type: TYPE_CHANGE_PASSWORD,
		isLoading: true,
	};
}

export function changePasswordSuccessAction(response) {
	toast.success("Hasło zostało zaktualizowane", getConfiguration());

	return {
		type: TYPE_CHANGE_PASSWORD_SUCCESS,
		isLoading: false,
		response: response,
	};
}

export function changePasswordFailureAction(error) {
	return {
		type: TYPE_CHANGE_PASSWORD_FAILURE,
		isLoading: false,
		error,
	};
}
