import React from "react";
import {useStyles} from "./advice.styles";
import classnames from "classnames";
import ErrorOutlineIcon from "@material-ui/icons/ErrorOutline";
import InfoIcon from "@material-ui/icons/Info";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";

export function AdviceComponent(props) {
	const {children, type} = props;
	const classes = useStyles({});

	function getIcon(type) {
		switch (type) {
			case "info":
			case "highlight":
				return (<InfoIcon className={classes.iconInfo} />);
			case "success":
				return (<CheckCircleIcon className={classes.iconSuccess} />);
			case "error":
				return (<ErrorOutlineIcon />);
			default:
				return (<InfoIcon />);
		}
	}

	return (
		<div className={classnames(classes.root, {[classes.typeHighlight]: type === "highlight"}, {[classes.typeError]: type === "error"}, {[classes.typeInfo]: type === "info"}, {[classes.typeSuccess]: type === "success"})}>
			<div className={classes.iconWrapper}>
				{getIcon(type)}
			</div>
			<div className={classes.textWrapper}>{children}</div>
		</div>
	);
}
