import {makeStyles} from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
	root: {
		margin: "15px 0",
		display: "flex",
	},
	alignContentRight: {
		alignItems: "center",
		justifyContent: "flex-end",
	},
	alignContentLeft: {
		alignItems: "center",
		justifyContent: "flex-start",
	},
	alignContentCenter: {
		alignItems: "center",
		justifyContent: "center",
	},
	hide: {
		display: "none"
	}
}));






