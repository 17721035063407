import React from "react";
import {Link, useHistory, useLocation} from "react-router-dom";
//import {useStyles} from "./updateTokens.styles";
import {Header} from "../../components/header/header.component";
import {Message} from "../../components/message";
import {Container} from "../../components/container/container.component";
import {Button} from "../../components/button/button";
import {FormFieldWrapper} from "../../components/formFieldWrapper/formFieldWrapper.component";
import {Locations} from "../../constants/locations";

export function UpdateTokensComponent(props) {
	const {/*error, updateFormData,*/ hasInvalidBDOTokens} = props;
	//const classes = useStyles({});

	/* eslint-disable */
	let history = useHistory();
	let location: any = useLocation();

	let { from } = location.state || { from: { pathname: "/" } };
	/* eslint-enable */

	if (hasInvalidBDOTokens) {
		return (
			<Container>
				<Header>
					Nie udało się zalogować do BDO
				</Header>
				<Message>
					<p>Wygląda na to że Twoje tokeny autoryzacyjne nie działają. Spróbuj zalogować się ponownie do aplikacji lub skontaktuj
						się z osobą odpowiedzialną za BDO w Twojej firmie aby
						potwierdzić czy nadal posiadasz uprawnienia do korzystania z aplikacji.</p>
					<p>W razie probemów zachęcamy do skorzystania z działu pomocy
						na stronie <a href="https://www.mbdo.pl/pomoc/tokeny-bdo.html" target="_blank">https://www.mbdo.pl/pomoc/tokeny-bdo.html</a> lub kontaktu z infolinią pod numerem <a href="tel:518071101">518 071 101</a>.</p>
				</Message>
			</Container>
		);
	} else {
		return (
			<Container>
				<Header>
					Brakujące tokeny BDO
				</Header>
				<Message>
					<p>Aby korzystać z MobileBDO musisz podłączyć swoje firmowe konto BDO
						poprzez ustawienie tokenów autoryzacyjnych <b>Client ID</b> oraz <b>Client Secret</b> w ustawieniach naszej aplikacji.</p>
					<FormFieldWrapper alignContent="center">
						<Link to={Locations.SETTINGS}>
							<Button variant="contained" color="primary">Przejdź do ustawień</Button>
						</Link>
					</FormFieldWrapper>
					<p>W razie problemów zachęcamy do skorzystania z działu pomocy
						na stronie <a href="https://www.mbdo.pl/pomoc/tokeny-bdo.html" target="_blank">https://www.mbdo.pl/pomoc/tokeny-bdo.html</a> lub kontaktu z infolinią pod numerem <a href="tel:518071101">518 071 101</a>.</p>
					<br /><br />
				</Message>
			</Container>
		);
	}
}



