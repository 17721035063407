import React from "react";
import {useStyles} from "./paragraph.styles";
import classnames from "classnames";

export function ParagraphComponent(props) {
	const {children} = props;
	const classes = useStyles({});

	return (
		<div className={classnames(classes.root)}>{children}</div>
	);
}
