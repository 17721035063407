import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
	curtain: {
		position: "fixed",
		top:0,
		left:0,
		bottom:0,
		right:0,
		zIndex: 100,
		display: "none",
		justifyContent: "center",
		alignItems: "center",
		color: "white",
	},
	modal: {
		backgroundColor: theme.palette.primary[50],
		zIndex: 90,
	},
	msg: {
		opacity: 1,
		fontFamily: theme.typography.fontFamily,
		fontSize: theme.typography.fontSize,
	},
	circle: {
		stroke: "white",
	},
	show: {
		display: "flex"
	}
}));

