import {makeStyles} from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
	root: {
		color: "black",
		padding: "0 0 5px 0",
		fontSize: "13px",
		fontFamily: theme.typography.fontFamily,
		display: "flex",
	},
}));
