import {UserStatus} from "../../constants/userStatus";
import Cookies from "js-cookie";
import jwtDecode from "jwt-decode";

export function getGetUserSuccessReducer(state, action) {
	const accessToken = Cookies.get("mbdo_at");
	const hasAccessToken = !!accessToken;
	const accessTokenData = hasAccessToken ? {
		original: accessToken,
		decoded: jwtDecode(accessToken),
	} : null;

	/*
		Decoded - invalid:
		exp: 1608560075
		iat: 1608473675
		sub: "5e5d5a924bbeb6004b335175"
	 */
	const isLoggedWithBDOTokens = Boolean(accessTokenData && accessTokenData.decoded.userinfo);


	return {
		...state,
		isLoading: action.isLoading,
		isDisconnected: false,
		isLoggedIn: true,
		accountData: action.response.data,
		isFetchingAccountData: false,
		error: null,
		isBlocked: action.response.data.status === UserStatus.BLOCKED,
		isExpired: Boolean(action.response.data.isExpired),
		hasBDOTokens: Boolean(action.response.data.hasBdoCredentials) && isLoggedWithBDOTokens,
		hasInvalidBDOTokens: !isLoggedWithBDOTokens,
		locationToChangeTo: action.locationToChangeTo,
	};
}
