import Cookies from "js-cookie";

export function getUpdateBDOCredentialsSuccessReducer(state, action) {
	Cookies.remove("mbdo_at");

	return {
		...state,
		isLoading: action.isLoading,
		isDisconnected: false,
		isLoggedIn: false,
		accessToken: null,
		accountData: null,
		error: null,
	};
}
