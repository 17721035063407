export function getServerUrl() {
	return `${process.env.REACT_APP_SERVER_PROTOCOL}://${process.env.REACT_APP_SEVER_URL}:${process.env.REACT_APP_SERVER_PORT}`;
}

export function flatternObjectToGetParams(obj) {
	if (!Object.keys(obj).length) {
		return "";
	}

	const params = [];

	for (let key in obj) {
		params.push(`${key}=${obj[key]}`);
	}

	return `?${params.join("&")}`;
}
