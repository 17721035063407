import React from "react";
import {
	BrowserRouter as Router,
	Switch,
	Route,
} from "react-router-dom";
import {LoginRoute} from "../../../../routes/login";
import {ManageReceptionsRoute} from "../../routes/manageReceptions";
import {ConfirmReceptionRoute} from "../../routes/confirmReception";
import {RejectReceptionRoute} from "../../routes/rejectReception";
import {SettingsRoute} from "../../../../routes/settings";
import {PrivateRoute} from "../../../../routes/privateRoute";
import {RegisterRoute} from "../../../../routes/register";
import {UpdateTokensRoute} from "../../../../routes/updateTokens";
import {AccountExpiredRoute} from "../../../../routes/accountExpired";
import {AccountBlockedRoute} from "../../../../routes/accountBlocked";
import {TopMenu} from "../../widgets/topMenu";
import {BottomMenu} from "../../widgets/bottomMenu";
import {RouterHelper} from "../../../../containers/routerHelper";
import {Loader} from "../../../../components/loader";
import {createMuiTheme, responsiveFontSizes} from "@material-ui/core/styles";
import {ThemeProvider} from "@material-ui/core";
import ScrollToTop from "../../../../components/scrollToTop/scrollToTop.component";
import {useStyles} from "./app.styles";
import {Locations} from "../../../../constants/locations";
import {ToastContainer} from "react-toastify";
import {SelectPlaceOfBusinessRoute} from "../../../../routes/selectPlaceOfBusiness";
import {AccountTypes} from "../../../../constants/accountTypes";
import {RoleTypes} from "../../../../constants/roleTypes";


var lightGreen = {
	50: '#005b7f',
	100: '#005b7f',
	200: '#005b7f',
	300: '#005b7f',
	400: '#005b7f',
	500: '#005b7f',
	600: '#005b7f',
	700: '#005b7f',
	800: '#005b7f',
	900: '#005b7f',
	A100: '#005b7f',
	A200: '#005b7f',
	A400: '#005b7f',
	A700: '#005b7f',
	contrastText: "#ffffff",
};

var gray = {
	50: '#dbdbdb',
	100: '#dbdbdb',
	200: '#dbdbdb',
	300: '#dbdbdb',
	400: '#dbdbdb',
	500: '#dbdbdb',
	600: '#dbdbdb',
	700: '#dbdbdb',
	800: '#dbdbdb',
	900: '#dbdbdb',
	A100: '#dbdbdb',
	A200: '#dbdbdb',
	A400: '#dbdbdb',
	A700: '#dbdbdb',
	contrastText: "#000000",
};

let theme = createMuiTheme({
	palette: {
		primary: lightGreen,
		secondary: gray,
	},
	typography: {
		fontSize: 14,
		fontFamily: "Lato",
	},
});

theme = responsiveFontSizes(theme);

export function AppComponent(props) {
	const classes = useStyles({});
	const {isLoggedIn, isLoading} = props;

	return (
		<ThemeProvider theme={theme}>
			<Router>
				<ScrollToTop />
				<RouterHelper />
				<TopMenu />
				<ToastContainer />
				<div className={classes.container}>
					<Switch>
						<Route path={Locations.REGISTER}>
							<RegisterRoute accountType={AccountTypes.RECEIVER} />
						</Route>
						<Route path={Locations.LOGIN}>
							<LoginRoute roleType={RoleTypes.RECIPENT} />
						</Route>
						<Route path={Locations.SELECT_PLACE_OF_BUSINESS}>
							<SelectPlaceOfBusinessRoute roleType={RoleTypes.RECIPENT} />
						</Route>
						<Route path={Locations.ACCOUNT_BLOCKED}>
							<AccountBlockedRoute />
						</Route>
						<PrivateRoute path={Locations.ACCOUNT_EXPIRED}>
							<AccountExpiredRoute />
						</PrivateRoute>
						<PrivateRoute path={Locations.UPDATE_TOKENS}>
							<UpdateTokensRoute />
						</PrivateRoute>
						<PrivateRoute path={Locations.SETTINGS}>
							<SettingsRoute />
						</PrivateRoute>
						<PrivateRoute path={Locations.MANAGE_RECEPTIONS}>
							<ManageReceptionsRoute />
						</PrivateRoute>
						<PrivateRoute path={Locations.CONFIRM_RECEPTION}>
							<ConfirmReceptionRoute />
						</PrivateRoute>
						<PrivateRoute path={Locations.REJECT_RECEPTION}>
							<RejectReceptionRoute />
						</PrivateRoute>
						<PrivateRoute path={Locations.HOME}>
							<ManageReceptionsRoute />
						</PrivateRoute>
					</Switch>
				</div>
				{isLoggedIn && <BottomMenu />}
			</Router>
			{isLoading && <Loader />}
		</ThemeProvider>
	);
}
