import {connect} from "react-redux";
import {UpdateTokensComponent} from "./updateTokens.component";
import {getError, hasInvalidBDOTokens} from "../../store/selectors/app.selectors";

const mapStateToProps = (state) => {
	return {
		error: getError(state),
		hasInvalidBDOTokens: hasInvalidBDOTokens(state),
	};
};

const mapDispatchToProps = (dispatch) => {
	return {

	};
};

export const UpdateTokensRoute = connect(
	mapStateToProps,
	mapDispatchToProps
)(UpdateTokensComponent);
