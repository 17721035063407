import {ILoginFormValues} from "../../interfaces/forms/login";
import {login as loginAPI} from "../../api/api";
import {TYPE_LOGIN, TYPE_LOGIN_FAILURE, TYPE_LOGIN_SUCCESS} from "./types";

export function login(roleType, formValues: ILoginFormValues) {
	return function(dispatch) {
		dispatch(loginAction());

		return loginAPI(roleType, formValues).then((response) => {
			dispatch(loginSuccessAction(response));
		}).catch((error) => {
			dispatch(loginFailureAction(error));
		});
	};
}

export function loginAction() {
	return {
		type: TYPE_LOGIN,
		isLoading: true,
	};
}

export function loginSuccessAction(response) {
	return {
		type: TYPE_LOGIN_SUCCESS,
		isLoading: false,
		response: response,
	};
}

export function loginFailureAction(error) {
	return {
		type: TYPE_LOGIN_FAILURE,
		isLoading: false,
		error,
	};
}
