import {createStore, applyMiddleware} from "redux";
import jwtDecode from "jwt-decode";
import reducers from "./reducers";
import thunk from "redux-thunk";
import {logger, crashReporter} from "../../../store/store.logger";
import Cookies from "js-cookie";
import {IAppState} from "./reducers/app.reducers";
import {ApiTypes} from "../../../constants/apiTypes";
// import moment from "moment";

const accessToken = Cookies.get("mbdo_at");
const apiType = Cookies.get("mbdo_api_type") || ApiTypes.KPO;
const hasAccessToken = !!accessToken;
const accessTokenData = hasAccessToken ? {
	original: accessToken,
	decoded: jwtDecode(accessToken),
} : null;

const isValidToken = accessTokenData && (accessTokenData.decoded.exp - Math.floor(new Date().getTime() / 1000) > 0);

// if (accessTokenData) {
// 	console.log(isValidToken, accessTokenData , accessTokenData.decoded.exp, Math.floor(new Date().getTime() / 1000), accessTokenData.decoded.exp - Math.floor(new Date().getTime() / 1000));
// 	console.log(moment.unix(accessTokenData.decoded.exp).format("YYYY-MM-DD hh:mm"))
// }


const defaultState: IAppState = {
	isDisconnected: false,
	isLoggedIn: isValidToken,
	isLoading: false,
	isBlocked: false,
	isExpired: false,
	hasBDOTokens: null,
	hasInvalidBDOTokens: false,
	accessToken: accessTokenData,
	accountData: null, // nie zapisuj tutaj danych z cookiesa, zeby za kazdym razem robic requesta i moc porownac z danymi po logowaniu
	isFetchingAccountData: false,
	isFetchingCardDetails: {},
	locationToChangeTo: null,
	refreshCurrentPageStatus: null,
	error: null,

	confirmationGeneratedCards: null,

	exchangedData: {},
	apiType: apiType,
	cardsDetails: {},
};

export default createStore(
	reducers,
	{
		app: defaultState,
	},
	applyMiddleware(logger, crashReporter, thunk),
);
