import {ICard} from "../interfaces/getApprovedCardsResponse";

export function getCurrentDate(): string {
	const now = new Date();

	return now.getFullYear() + "-" + fillZeros(now.getMonth() + 1) + "-" + fillZeros(now.getDate());
}

export function getCurrentTime(): string {
	const now = new Date();

	return fillZeros(now.getHours()) + ":" + fillZeros(now.getMinutes());
}

export function fillZeros(nr: number):string {
	return nr < 10 ? "0" + nr : String(nr);
}

export function sortByDateAsc(items: ICard[], dateAttrName, asc = true) {
	return items.sort((a, b) => {
		const aTimestamp = new Date(a[dateAttrName]).getTime();
		const bTimestamp = new Date(b[dateAttrName]).getTime();

		if (asc) {
			if (aTimestamp > bTimestamp) {return 1;}
			if (aTimestamp < bTimestamp) {return -1;}
		} else {
			if (aTimestamp < bTimestamp) {return 1;}
			if (aTimestamp > bTimestamp) {return -1;}
		}

		return 0;
	});
}

export function getFormattedDateTime(dateStr) {
	// const dateStr2 = "2020-04-09T13:00:00.000Z";

	const date = new Date(dateStr.substr(0, dateStr.length - 1));

	// console.log("-----", moment.utc(dateStr).format("YYYY-MM-DD hh:mm"), date.getFullYear() + "-" + fillZeros(date.getMonth() + 1) + "-" + fillZeros(date.getDate()) + " " + fillZeros(date.getHours()) + ":" + fillZeros(date.getMinutes()));
// console.log("dateStr", dateStr);
	//console.log("2020-04-09T13:00:00.000Z", moment.utc("2020-04-09T13:00:00.000Z").format("YYYY-MM-DD hh:mm"), date.getFullYear() + "-" + fillZeros(date.getMonth() + 1) + "-" + fillZeros(date.getDate()) + " " + fillZeros(date.getHours()) + ":" + fillZeros(date.getMinutes()))

	//return moment.utc(dateStr).format("YYYY-MM-DD hh:mm");

	return date.getFullYear() + "-" + fillZeros(date.getMonth() + 1) + "-" + fillZeros(date.getDate()) + " " + fillZeros(date.getHours()) + ":" + fillZeros(date.getMinutes());
}

export function getFormattedDate(dateStr) {
	const date = new Date(dateStr.substr(0, dateStr.length - 1));

	// console.log("-----", moment.utc(dateStr).format("YYYY-MM-DD hh:mm"), date.getFullYear() + "-" + fillZeros(date.getMonth() + 1) + "-" + fillZeros(date.getDate()) + " " + fillZeros(date.getHours()) + ":" + fillZeros(date.getMinutes()));

	// return moment.utc(dateStr).format("YYYY-MM-DD");

	return date.getFullYear() + "-" + fillZeros(date.getMonth() + 1) + "-" + fillZeros(date.getDate()) + " " + fillZeros(date.getHours()) + ":" + fillZeros(date.getMinutes());
}

export function getDayDifference(date1, data2) {
	date1.setHours(0);
	date1.setMinutes(0);
	date1.setSeconds(0);

	data2.setHours(0);
	data2.setMinutes(0);
	data2.setSeconds(0);

	return Math.round((data2.getTime() - date1.getTime()) / (24 * 3600 * 1000));
}

export function getHumanDays(days) {
	const positiveNumbers = Math.abs(days);

	switch(days) {
		case 0:
			return "dzisiaj";
		case -1:
			return "wczoraj";
		case -2:
			return "przedwczoraj";
		case 1:
			return "jutro";
		case 2:
			return "pojutrze";
	}

	if (days > 0) {
		return `za ${positiveNumbers} dni`;
	} else if (days < 0) {
		return `${positiveNumbers} dni temu`;
	} else {
		// wszystkie casy powinny byc juz obsłuzone, ale w razie gdyby wyswietl to co kiedys
		return `${days} ${(positiveNumbers === 1 ? "dzień" : "dni")}`;
	}
}
