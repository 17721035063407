export enum RoleTypes {
	CONVEYOR = "CONVEYOR",
	RECIPENT = "RECIPENT",
	ADMIN = "ADMIN",
}

export const RoleTypesList = [
	"CONVEYOR",
	"RECIPENT",
	"ADMIN",
];
