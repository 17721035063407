import {confirmReception as confirmReceptionAPI} from "../../api/api";
import {TYPE_CONFIRM_RECEPTION, TYPE_CONFIRM_RECEPTION_FAILURE, TYPE_CONFIRM_RECEPTION_SUCCESS} from "./types";
import {toast} from "react-toastify";
import {getConfiguration} from "../../../../helpers/toast";

export function confirmReception(apiType, cardId, correctedWasteMass, remarks) {
	return function(dispatch) {
		dispatch(confirmReceptionAction());

		return confirmReceptionAPI(apiType, cardId, correctedWasteMass, remarks).then((response) => {
			dispatch(confirmReceptionSuccessAction(response));
		}).catch((error) => {
			dispatch(confirmReceptionFailureAction(error));
		});
	};
}

export function confirmReceptionAction() {
	return {
		type: TYPE_CONFIRM_RECEPTION,
		isLoading: true,
	};
}

export function confirmReceptionSuccessAction(response) {
	toast.success("Przejęcie zostało potwierdzone", getConfiguration());

	return {
		type: TYPE_CONFIRM_RECEPTION_SUCCESS,
		isLoading: false,
		response: response,
	};
}

export function confirmReceptionFailureAction(error) {
	return {
		type: TYPE_CONFIRM_RECEPTION_FAILURE,
		isLoading: false,
		error,
	};
}
