import * as React from "react";
import {useHistory} from "react-router-dom";
import {useStyles} from "./bottomMenu.styles";
import BottomNavigation from '@material-ui/core/BottomNavigation';
import BottomNavigationAction from '@material-ui/core/BottomNavigationAction';
import ActiveTransportsIcon from "@material-ui/icons/Assignment";
import {Locations} from "../../../../constants/locations";
import {AccountCircle} from "@material-ui/icons";

export function BottomMenuComponent(props) {
	const {hasBDOTokens, isExpired} = props;
	const classes = useStyles({});
	let history = useHistory();

	const selectedOption = history.location.pathname === Locations.MANAGE_RECEPTIONS || history.location.pathname === Locations.HOME ? 0 : -1;

	function handleChange(e, newValue) {
		switch(newValue) {
			case 0:
				history.push(Locations.MANAGE_RECEPTIONS);
				break;
		}
	}

	if (hasBDOTokens === false) {
		return (
			<BottomNavigation
				value={selectedOption}
				onChange={() => {history.push(Locations.SETTINGS);}}
				showLabels
				classes={{root: classes.rootError}}
			>
				<BottomNavigationAction classes={{root: classes.buttonRoot, selected: classes.buttonSelected, label: classes.buttonLabel}} label="Aby korzystać z aplikacji, uzupełnij tokeny BDO" icon={<AccountCircle />} />
			</BottomNavigation>
		);
	} else {
		return (
			<BottomNavigation
				value={selectedOption}
				onChange={handleChange}
				showLabels
				className={classes.root}
			>
				<BottomNavigationAction disabled={isExpired} classes={{root: classes.buttonRoot, selected: classes.buttonSelected, label: classes.buttonLabel}} label="Karty odbiorów" icon={<ActiveTransportsIcon />} />
			</BottomNavigation>
		);
	}
}



