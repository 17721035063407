import {connect} from "react-redux";
import {PrivateRouteComponent} from "./privateRoute.component";
import {
	getAccessToken,
	isLoggedIn,
	isFetchingAccountData,
	isBlocked,
	isExpired,
	hasBdoCredentials,
	getAccountData
} from "../../store/selectors/app.selectors";
import {getUser} from "../../store/actions";

const mapStateToProps = (state) => {
	return {
		isLoggedIn: isLoggedIn(state),
		accessToken: getAccessToken(state),
		isFetchingAccountData: isFetchingAccountData(state),
		isBlocked: isBlocked(state),
		isExpired: isExpired(state),
		hasBdoCredentials: hasBdoCredentials(state),
		accountData: getAccountData(state),
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		getUser: (userId, locationToChangeTo) => {dispatch(getUser(userId, locationToChangeTo));},
	};
};

export const PrivateRoute = connect(
	mapStateToProps,
	mapDispatchToProps
)(PrivateRouteComponent);
