import * as React from "react";
import {useStyles} from "./cardWrapper.styles";
import classnames from "classnames";

export function CardWrapper(props) {
	const {children, type} = props;
	const classes = useStyles({});

	return (
		<div className={classnames(classes.root, {[classes[type]]: type})}>
			{children}
		</div>
	);
}

