import {makeStyles} from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
	root: {
		border: "none",
		borderRadius: "0",
		margin: "0 0 15px 0",
		position: "relative",
		boxShadow: "0 5px 10px rgba(0, 9, 128, 0.035), 0 7px 18px rgba(0, 9, 128, 0.05)",

		"&::before": {
			content: " ",
			width: "2px",
			height: "100% !important",
			position: "absolute",
			top: "0",
			left: "-2px",
			// background: "rgba(59, 181, 115, 1)",
			background: "linear-gradient(-45deg, rgba(0, 114, 54, 1), rgba(59, 181, 115, 1))",
		}
	},

	expanded: {
		"&::before": {
			opacity: "1 !important"
		}
	},
	summaryRoot: {
		fontSize: "15px",
		fontWeight: 600,
		padding: "0 10px",
	},
	detailsRoot: {
		padding: "0 10px",
	}
}));
