import React from "react";
import {Form} from "react-final-form";
import {Textbox} from "../../components/textbox/textbox";
import {Button} from "../../components/button/button";
import {ErrorMessage} from "../../components/errorMessage";
import {useHistory, useLocation} from "react-router-dom";
import {IRegisterFormValues} from "../../interfaces/forms/register";
import {useStyles} from "./register.styles";
import {globalStyles} from "../../css/global.styles";
import {Header} from "../../components/header/header.component";
import {Container} from "../../components/container/container.component";
import {FormFieldWrapper} from "../../components/formFieldWrapper/formFieldWrapper.component";
import {Checkbox} from "../../components/checkbox/checkbox.component";
import {Locations} from "../../constants/locations";
import {ButtonText} from "../../components/buttonText";
import {isValidPassword, isValidEmail, isValidLogin, containsSpaces} from "../../helpers/validation";
declare var grecaptcha: any;

export function RegisterComponent(props) {
	const {register, error, updateFormData, accountType} = props;
	const classes = useStyles({});
	const globalClasses = globalStyles({});

	/* eslint-disable */
	let history = useHistory();
	let location: any = useLocation();
	/* eslint-enable */

	const initialValues: IRegisterFormValues = {
		login: "",
		email: "",
		password: "",
		confirmedPassword: "",
		regulationsConfirmation: false,
	};

	const onSubmit = async (values: IRegisterFormValues) => {
		grecaptcha.execute("6LdUgNgUAAAAAAEHug497JqvesEy9eQCdWETSiui", {action: "signup"}).then(function(token) {
			updateFormData({
				from: Locations.REGISTER,
				type: "loginAfterRegistration",
				login: values.login,
			});

			register(accountType, values, Locations.LOGIN, token);
		});
	};

	const handleLoginButtonClick = () => {
		history.push(Locations.LOGIN);
	};

	const handleValidation = (values: IRegisterFormValues) => {
		const errors: any = {};

		if (!values.login) {
			errors.login = "To pole jest wymagane";
		}

		if (!isValidLogin(values.login)) {
			errors.login = "Nazwa użytkownika powinna składać się z 5 do 50 znaków";
		}

		if (containsSpaces(values.login)) {
			errors.login = "Nazwa użytkownika nie może zawierac spacji";
		}

		if (!values.email) {
			errors.email = "To pole jest wymagane";
		}

		if (!isValidEmail(values.email)) {
			errors.email = "Niepoprawny adres e-mail";
		}

		if (containsSpaces(values.email)) {
			errors.email = "E-mail nie może zawierac spacji";
		}

		if (!isValidPassword(values.password)) {
			errors.password = "Hasło powinno składać się z 8 do 20 znaków (małe oraz duże litery, cyfry)";
		}

		if (containsSpaces(values.password)) {
			errors.password = "Hasło nie może zawierac spacji";
		}

		if (!values.confirmedPassword) {
			errors.confirmedPassword = "To pole jest wymagane";
		}

		if (containsSpaces(values.confirmedPassword)) {
			errors.confirmedPassword = "Hasło nie może zawierac spacji";
		}

		if (values.password !== values.confirmedPassword) {
			errors.confirmedPassword = "Hasła muszą być identyczne";
		}

		if (!values.regulationsConfirmation) {
			errors.regulationsConfirmation = "Aby skorzystać z usługi musisz zaakceptować regulamin oraz politykę prywatności";
		}

		return errors;
	};

	return (
		<Container>
			<Header>
				Rejestracja
			</Header>
			<Form
				validate={handleValidation}
				onSubmit={onSubmit}
				initialValues={initialValues}
				render={({ handleSubmit, form, submitting, pristine, values }) => {
					return (
						<form onSubmit={handleSubmit} noValidate={true}>
							<FormFieldWrapper>
								<Textbox
									required={true}
									name="email"
									label="Adres e-mail"
									className={globalClasses.inputWidth}
									serverErrors={error}
								/>
							</FormFieldWrapper>
							<FormFieldWrapper>
								<Textbox
									required={true}
									name="login"
									label="Nazwa użytkownika"
									className={globalClasses.inputWidth}
									serverErrors={error}
								/>
							</FormFieldWrapper>
							<FormFieldWrapper>
								<Textbox
									required={true}
									name="password"
									type="password"
									label="Hasło"
									className={globalClasses.inputWidth}
								/>
							</FormFieldWrapper>
							<FormFieldWrapper>
								<Textbox
									required={true}
									name="confirmedPassword"
									type="password"
									label="Powtórz hasło"
									className={globalClasses.inputWidth}
								/>
							</FormFieldWrapper>

							<FormFieldWrapper>
								<Checkbox name="regulationsConfirmation" alignLabel="top">Oświadczam, że zapoznałem(-am) się z regulaminem , wyrażam zgodę na jego postanowienia i chcę korzystać z usługi MobileBDO opisanej w ww. <a href="https://www.mbdo.pl/regulamin.html" target="_blank">regulaminie</a> i w <a href="https://www.mbdo.pl/polityka-prywatnosci.html" target="_blank">polityce prywatności</a></Checkbox>
							</FormFieldWrapper>
							<FormFieldWrapper alignContent="right">
								<ButtonText className={classes.loginButton} onClick={handleLoginButtonClick}>Zaloguj się</ButtonText>
								<Button variant="contained" color="primary" type="submit" disabled={Boolean(Object.keys(handleValidation(values)).length)}>Zarejestruj się</Button>
							</FormFieldWrapper>

							<ErrorMessage errors={error} type="general" />
						</form>
					);
				}}
			/>
		</Container>
	);
}



