import {connect} from "react-redux";
import {ManageReceptionsComponent} from "./manageReceptions.component";
import {fetchConfirmationGeneratedCards} from "../../store/actions";
import {updateFormDataAction} from "../../../../store/actions";
import {getConfirmationGeneratedCards} from "../../store/selectors/app.selectors";
import {getApiType, getFormData} from "../../../../store/selectors/app.selectors";

const mapStateToProps = (state) => {
	return {
		confirmationGeneratedCards: getConfirmationGeneratedCards(state),
		exchangedData: getFormData(state),
		apiType: getApiType(state),
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		fetchConfirmationGeneratedCards: (apiType) => {dispatch(fetchConfirmationGeneratedCards(apiType));},
		updateFormData: (exchangedData) => {dispatch(updateFormDataAction(exchangedData));},
	};
};

export const ManageReceptionsRoute = connect(
	mapStateToProps,
	mapDispatchToProps
)(ManageReceptionsComponent);
