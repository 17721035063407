import {connect} from "react-redux";
import {AppComponent} from "./app.component";
import {isLoading, isLoggedIn} from "../../../../store/selectors/app.selectors";

const mapStateToProps = (state) => {
	return {
		isLoggedIn: isLoggedIn(state),
		isLoading: isLoading(state),
	};
};

const mapDispatchToProps = (dispatch) => {
	return {

	};
};

export const App = connect(
	mapStateToProps,
	mapDispatchToProps
)(AppComponent);
