export function getConfirmationGeneratedCards(state) {
	return state.app.confirmationGeneratedCards;
}

export function isFetchingCardDetails(state) {
	return state.app.isFetchingCardDetails;
}

export function getCardsDetails(state) {
	return state.app.cardsDetails;
}
