import React from "react";
import {Form} from "react-final-form";
import {Textarea} from "../../../../components/textarea/textarea";
import {Button} from "../../../../components/button/button";
import {ErrorMessage} from "../../../../components/errorMessage";
import {ISettingsTokensFormValues} from "../../../../interfaces/forms/settingsTokens";
import {useStyles} from "./tokens.styles";
import {globalStyles} from "../../../../css/global.styles";
import {FormFieldWrapper} from "../../../../components/formFieldWrapper/formFieldWrapper.component";
import {Advice} from "../../../../components/advice";
import {containsSpaces} from "../../../../helpers/validation";
import {useHistory, useLocation} from "react-router-dom";
import {getUserId} from "../../../../helpers/user";

export function TokensComponent(props) {
	const {error, accessToken, updateBDOCredentials, hasBdoCredentials} = props;
	const classes = useStyles({});
	const globalClasses = globalStyles({});

	/* eslint-disable */
	let history = useHistory();
	let location: any = useLocation();

	let { from } = location.state || { from: { pathname: "/" } };
	/* eslint-enable */

	const initialValues: ISettingsTokensFormValues = {
		clientId: "",
		clientKey: "",
	};

	const onSubmit = async (values: ISettingsTokensFormValues) => {
		const userId = getUserId(accessToken);
		updateBDOCredentials(userId, values);
	};

	const handleValidation = (values: ISettingsTokensFormValues) => {
		const errors: any = {};

		if (!values.clientId) {
			errors.clientId = "To pole jest wymagane";
		}

		if (containsSpaces(values.clientId)) {
			errors.clientId = "Client ID nie może zawierac spacji";
		}

		if (!values.clientKey) {
			errors.clientKey = "To pole jest wymagane";
		}

		if (containsSpaces(values.clientKey)) {
			errors.clientKey = "Client Secret nie może zawierac spacji";
		}

		return errors;
	};

	return (
		<>
			<Form
				validate={handleValidation}
				onSubmit={onSubmit}
				initialValues={initialValues}
				render={({ handleSubmit, form, submitting, pristine, values }) => {
					return (
						<form onSubmit={handleSubmit} className={classes.form}>
							{hasBdoCredentials && <Advice type="success">Twoje tokeny są poprawnie zapisane w systemie. Nie wyświetlamy ich ze względów bezpieczeństwa. Jeśli chcesz je zaktualizować, wpisz je w poniższe pola oraz kliknij przycisk Zapisz zmiany.</Advice>}
							<Advice type="success">Zmiany tokenów autoryzacyjnych BDO wymagają ponownego zalogowania się.</Advice>
							<FormFieldWrapper>
								<Textarea
									name="clientId"
									label="Client ID"
									className={globalClasses.inputWidth}
									serverErrors={error}
								/>
							</FormFieldWrapper>
							<FormFieldWrapper>
								<Textarea
									name="clientKey"
									label="Client Secret"
									className={globalClasses.inputWidth}
									serverErrors={error}
								/>
							</FormFieldWrapper>
							<FormFieldWrapper alignContent="right">
								<Button variant="contained" color="primary" type="submit" disabled={Boolean(Object.keys(handleValidation(values)).length)}>Zapisz zmiany</Button>
							</FormFieldWrapper>

							<ErrorMessage errors={error} type="general" />
						</form>
					);
				}}
			/>
		</>
	);
}



