import React from "react";
import {useStyles} from "./accordion.styles";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import Accordion from "@material-ui/core/Accordion";

export function AccordionComponent(props) {
	const {children, title} = props;
	const classes = useStyles({});

	return (
		<Accordion classes={{root: classes.root, expanded: classes.expanded}}>
			<AccordionSummary
				classes={{root: classes.summaryRoot}}
				expandIcon={<ExpandMoreIcon />}
			>
				{title}
			</AccordionSummary>
			<AccordionDetails classes={{root: classes.detailsRoot}}>
				{children}
			</AccordionDetails>
		</Accordion>
	);
}
