import {Locations} from "../../constants/locations";

export function getRejectReceptionSuccessReducer(state, action) {
	return {
		...state,
		isLoading: action.isLoading,
		isDisconnected: false,
		error: null,
		locationToChangeTo: Locations.MANAGE_RECEPTIONS,
		exchangedData: {},
	};
}
