import {connect} from "react-redux";
import {RegistrationNumberSectionComponent} from "./registrationNumber.component";

const mapStateToProps = (state) => {
	return {

	};
};

const mapDispatchToProps = (dispatch) => {
	return {

	};
};

export const RegistrationNumberSection = connect(
	mapStateToProps,
	mapDispatchToProps
)(RegistrationNumberSectionComponent);
