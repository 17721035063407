import {connect} from "react-redux";
import {SettingsComponent} from "./settings.component";
import {getError} from "../../store/selectors/app.selectors";


const mapStateToProps = (state) => {
	return {
		error: getError(state),
	};
};

const mapDispatchToProps = (dispatch) => {
	return {

	};
};

export const SettingsRoute = connect(
	mapStateToProps,
	mapDispatchToProps
)(SettingsComponent);
