import {TYPE_CHANGE_API_TYPE} from "./types";
import Cookies from "js-cookie";

export function changeApiTypeAction(apiType) {
	Cookies.set("mbdo_api_type", apiType);

	return {
		type: TYPE_CHANGE_API_TYPE,
		apiType,
	};
}
