import React, {useEffect} from "react";
import {get as lodashGet} from "lodash";
import {Form} from "react-final-form";
import {Button} from "../../../../components/button/button";
import {globalStyles} from "../../../../css/global.styles";
import {IConfirmReceptionFormValues} from "../../interfaces/forms/confirmReception";
import {Textarea} from "../../../../components/textarea/textarea";
import {TextboxNumber} from "../../../../components/textboxNumber/textboxNumber";
import {Header} from "../../../../components/header/header.component";
import {Locations} from "../../../../constants/locations";
import {Redirect, useHistory} from "react-router-dom";
import {Container} from "../../../../components/container/container.component";
import {FormFieldWrapper} from "../../../../components/formFieldWrapper/formFieldWrapper.component";
import {ErrorMessage} from "../../../../components/errorMessage";
import {CardTypes} from "../../../../constants/cardTypes";

export function ConfirmReceptionComponent(props) {
	const {exchangedData, confirmReception, error, updateFormData, apiType, getCardDetails, isFetchingCardDetails, cardsDetails} = props;
	const globalClasses = globalStyles({});
	const cardId = lodashGet(exchangedData, "formValues.cardId");

	let history = useHistory();

	useEffect(() => {
		if (cardId) {
			getCardDetails(apiType, cardId, CardTypes.APPROVED);
		}
	}, []);

	const handleGoBack = () => {
		updateFormData({
			from: Locations.CONFIRM_RECEPTION,
			type: "goBack",
			formValues: lodashGet(exchangedData, 'formValues'),
		});
		history.push(Locations.MANAGE_RECEPTIONS);
	};

	const handleValidation = (values: IConfirmReceptionFormValues) => {
		const errors: any = {};

		if (!values.correctedWasteMass) {
			errors.correctedWasteMass = "To pole jest wymagane";
		} else if (parseFloat(values.correctedWasteMass) === 0) {
			errors.correctedWasteMass = "Masa nie może równać się 0";
		} else if (!/^(\d*)(\.(\d*))?$/.test(values.correctedWasteMass)) {
			errors.correctedWasteMass = "Masa może zostać wyrażona tylko za pomocą liczb oraz przecinka. Np: 1 lub 1,23";
		}

		return errors;
	};

	const onSubmit = async (values: IConfirmReceptionFormValues) => {
		confirmReception(apiType, cardId, Number.parseFloat(values.correctedWasteMass), values.remarks);
	};

	if (!Object.keys(lodashGet(exchangedData, "formValues", {})).length) {
		return (
			<Redirect
				to={{
					pathname: Locations.MANAGE_RECEPTIONS
				}}
			/>
		);
	} else {
		const isFetching = isFetchingCardDetails[cardId] || false;
		const cardDetails = cardsDetails[cardId] || null;

		// cardsDetails
		const initialValues: IConfirmReceptionFormValues = {
			correctedWasteMass: isFetching ? "Trwa pobieranie masy..." : lodashGet(cardDetails, "wasteMass", "0"),
			remarks: "",
		};

		return (
			<Container>
				<Header>
					Potwierdź przejęcie odpadów
				</Header>
				<Form
					onSubmit={onSubmit}
					validate={handleValidation}
					initialValues={initialValues}
					render={({ handleSubmit, form, submitting, pristine, values }) => (
						<form onSubmit={handleSubmit} noValidate={true}>
							<FormFieldWrapper>
								<TextboxNumber
									required={true}
									name="correctedWasteMass"
									label="Masa"
									labelUnit={isFetching ? "Trwa pobieranie masy...": "Mg"}
									className={globalClasses.inputWidth}
									serverErrors={error}
									disabled={isFetching}
								/>
							</FormFieldWrapper>
							<FormFieldWrapper>
								<Textarea
									label="Komentarz"
									name="remarks"
									className={globalClasses.inputWidth}
									serverErrors={error}
								/>
							</FormFieldWrapper>
							<FormFieldWrapper alignContent="right">
								<Button onClick={handleGoBack} className={globalClasses.centerButton} variant="contained" color="secondary">
									Cofnij
								</Button>
								<Button className={globalClasses.centerButton} variant="contained" color="primary" type="submit" disabled={submitting || isFetching}>
									Potwierdź
								</Button>
							</FormFieldWrapper>

							<ErrorMessage errors={error} type="general" />
						</form>
					)}
				/>
			</Container>
		);
	}
}



