export function getError(state) {
	return state.app.error;
}

export function isLoggedIn(state) {
	return state.app.isLoggedIn;
}

export function getLocationToChangeTo(state) {
	return state.app.locationToChangeTo;
}

export function getRefreshCurrentPageStatus(state) {
	return state.app.refreshCurrentPageStatus;
}

export function isLoading(state) {
	return state.app.isLoading;
}

export function getFormData(state) {
	return state.app.exchangedData;
}

export function getPlacesOfBusiness(state) {
	return state.app.placesOfBusiness;
}

export function getAccessToken(state) {
	return state.app.accessToken;
}

export function isFetchingAccountData(state) {
	return state.app.isFetchingAccountData;
}

export function getAccountData(state) {
	return state.app.accountData;
}

export function isBlocked(state) {
	return state.app.isBlocked;
}

export function isExpired(state) {
	return state.app.isExpired;
}

export function hasBdoCredentials(state) {
	return state.app.hasBDOTokens;
}

export function hasInvalidBDOTokens(state) {
	return state.app.hasInvalidBDOTokens;
}

export function getApiType(state) {
	return state.app.apiType;
}
