import * as React from "react";

// export function RouterHelperComponent(props) {
// 	const {locationToChangeTo} = props;
// 	console.log("====", locationToChangeTo);
// 	return null;
// }

export class RouterHelperComponent extends React.Component<any, any> {
	componentDidUpdate(prevProps) {
		const {locationToChangeTo, history, clearLocationToChangeTo} = this.props;
		const {previousLocationToChangeTo} = prevProps;

		if (locationToChangeTo !== null && previousLocationToChangeTo !== locationToChangeTo) {
			clearLocationToChangeTo();
			history.push(locationToChangeTo);
		}
	}

	render() {
		return null;
	}
}
