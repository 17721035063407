import {connect} from "react-redux";
import {TokensComponent} from "./tokens.component";
import {getAccessToken, getError, hasBdoCredentials} from "../../../../store/selectors/app.selectors";
import {updateBDOCredentials} from "../../../../store/actions";
import {ISettingsTokensFormValues} from "../../../../interfaces/forms/settingsTokens";

const mapStateToProps = (state) => {
	return {
		error: getError(state),
		hasBdoCredentials: hasBdoCredentials(state),
		accessToken: getAccessToken(state),
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		updateBDOCredentials: (userId, formValues: ISettingsTokensFormValues) => {dispatch(updateBDOCredentials(userId, formValues));},
	};
};

export const TokensSection = connect(
	mapStateToProps,
	mapDispatchToProps
)(TokensComponent);
