import {connect} from "react-redux";
import {RejectReceptionComponent} from "./rejectReception.component";
import {getApiType, getFormData} from "../../../../store/selectors/app.selectors";
import {getError} from "../../../../store/selectors/app.selectors";
import {rejectReception} from "../../store/actions";
import {updateFormDataAction} from "../../../../store/actions/updateFormData.actions";

const mapStateToProps = (state) => {
	return {
		exchangedData: getFormData(state),
		error: getError(state),
		apiType: getApiType(state),
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		rejectReception: (apiType, cardId, remarks) => {dispatch(rejectReception(apiType, cardId, remarks));},
		updateFormData: (exchangedData) => {dispatch(updateFormDataAction(exchangedData));},
	};
};

export const RejectReceptionRoute = connect(
	mapStateToProps,
	mapDispatchToProps
)(RejectReceptionComponent);
