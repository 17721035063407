import React, {useEffect} from "react";
// import {useStyles} from "./privateRoute.styles";
import {Route, Redirect} from "react-router-dom";
import {Locations} from "../../constants/locations";
import {AccountExpiredRoute} from "../accountExpired";
import {UpdateTokensRoute} from "../updateTokens";
import {hasAccountData} from "../../helpers/account";

export function PrivateRouteComponent(props) {
	const {
		children, path, isLoggedIn,
		accessToken, getUser, isFetchingAccountData,
		isExpired, isBlocked, hasBdoCredentials, accountData
	} = props;
	// const classes = useStyles({});

	useEffect(() => {
		if (isLoggedIn && !hasAccountData(accountData) && !isFetchingAccountData) {
			getUser(accessToken.decoded.sub, null);
		}
	}, []);

	function render({ location }) {
		if (isLoggedIn && !isBlocked) {
			if (isExpired) {
				return (<AccountExpiredRoute />);
			} else if (!isFetchingAccountData && !hasBdoCredentials && location.pathname !== Locations.SETTINGS && process.env.REACT_APP_APPTYPE !== "admin") {
				return (<UpdateTokensRoute />);
			} else {
				return (children);
			}
		} else {
			return (
				<Redirect
					to={{
						pathname: Locations.LOGIN,
						state: {
							from: location,
						}
					}}
				/>
			);
		}
	}

	return (
		<Route
			path={path}
			render={render}
		/>
	);
}
