export const TYPE_UPDATE_BDO_CREDENTIALS = "TYPE_UPDATE_BDO_CREDENTIALS";
export const TYPE_UPDATE_BDO_CREDENTIALS_SUCCESS = "TYPE_UPDATE_BDO_CREDENTIALS_SUCCESS";
export const TYPE_UPDATE_BDO_CREDENTIALS_FAILURE = "TYPE_UPDATE_BDO_CREDENTIALS_FAILURE";
export const TYPE_CLEAR_LOCATION_TO_CHANGE_TO = "TYPE_CLEAR_LOCATION_TO_CHANGE_TO";
export const TYPE_CLEAR_REFRESH_CURRENT_PAGE = "TYPE_RESET_REFRESH_CURRENT_PAGE";
export const TYPE_GET_USER = "TYPE_GET_USER";
export const TYPE_GET_USER_SUCCESS = "TYPE_GET_USER_SUCCESS";
export const TYPE_GET_USER_FAILURE = "TYPE_GET_USER_FAILURE";
export const TYPE_LOGIN = "TYPE_LOGIN";
export const TYPE_LOGIN_SUCCESS = "TYPE_LOGIN_SUCCESS";
export const TYPE_LOGIN_FAILURE = "TYPE_LOGIN_FAILURE";
export const TYPE_LOGOUT = "TYPE_LOGOUT";
export const TYPE_REGISTER = "TYPE_REGISTER";
export const TYPE_REGISTER_SUCCESS = "TYPE_REGISTER_SUCCESS";
export const TYPE_REGISTER_FAILURE = "TYPE_REGISTER_FAILURE";
export const TYPE_UPDATE_FORM_DATA = "TYPE_UPDATE_FORM_DATA";

export const TYPE_CHANGE_PASSWORD = "TYPE_CHANGE_PASSWORD";
export const TYPE_CHANGE_PASSWORD_SUCCESS = "TYPE_CHANGE_PASSWORD_SUCCESS";
export const TYPE_CHANGE_PASSWORD_FAILURE = "TYPE_CHANGE_PASSWORD_FAILURE";
export const TYPE_CHANGE_API_TYPE = "TYPE_CHANGE_API_TYPE";
