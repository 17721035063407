import {IRegisterFormValues} from "../../interfaces/forms/register";
import {getUser as getUserAPI} from "../../api/api";
import {TYPE_GET_USER, TYPE_GET_USER_FAILURE, TYPE_GET_USER_SUCCESS} from "./types";
import {toast} from "react-toastify";
import {getAccountExpirationConfiguration} from "../../helpers/toast";
import moment from "moment";
import {getFormattedDate, getHumanDays} from "../../helpers/datetime";

export function getUser(formValues: IRegisterFormValues, locationToChangeTo) {
	return function(dispatch) {
		dispatch(getUserAction());

		return getUserAPI(formValues).then((response) => {
			dispatch(getUserSuccessAction(response, locationToChangeTo, formValues));
		}).catch((error) => {
			dispatch(getUserFailureAction(error));
		});
	};
}

export function getUserAction() {
	return {
		type: TYPE_GET_USER,
		isLoading: true,
	};
}

function showAccountExpiredToast(accountData): void {
	if (!accountData) {
		return;
	}

	const date1 = moment.utc(accountData.validUntil);
	const date2 = moment.utc();
	const daysDiff = date1.diff(date2, "days");

	if (daysDiff>= 0 && daysDiff <= 5) {
		toast.error(`${daysDiff > 0 ? "Za " : ""} ${getHumanDays(daysDiff)} (${getFormattedDate(accountData.validUntil)}) kończy się abonament. Prosimy o opłacenie faktury.`, getAccountExpirationConfiguration());
	} else {
		return;
	}
}

export function getUserSuccessAction(response, locationToChangeTo, formValues) {
	showAccountExpiredToast(response.data);

	return {
		type: TYPE_GET_USER_SUCCESS,
		isLoading: false,
		response: response,
		locationToChangeTo,
		formValues,
	};
}

export function getUserFailureAction(error) {
	return {
		type: TYPE_GET_USER_FAILURE,
		isLoading: false,
		error,
	};
}
