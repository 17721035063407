import {IGetCardsResponse} from "../../interfaces/getApprovedCardsResponse";
import {sortByDateAsc} from "../../helpers/datetime";

export function getFetchConfirmationGeneratedCardsSuccessReducer(state, action) {
	const confirmationGeneratedCardsResponse: IGetCardsResponse = action.response.data;

	return {
		...state,
		isLoading: action.isLoading,
		isDisconnected: false,
		error: null,
		confirmationGeneratedCards: sortByDateAsc(confirmationGeneratedCardsResponse.items, "realTransportTime"),
	};
}
