import Cookies from "js-cookie";
import jwtDecode from "jwt-decode";

export function getLoginSuccessReducer(state, action) {
	Cookies.set("mbdo_at", action.response.data.access_token);

	return {
		...state,
		isLoading: action.isLoading,
		isDisconnected: false,
		isLoggedIn: true,
		accessToken: {
			original: action.response.data.access_token,
			decoded: jwtDecode(action.response.data.access_token),
		},
		error: null,
	};
}
