import React from "react";
import {useHistory, useLocation} from "react-router-dom";
// import {useStyles} from "./accountBlocked.styles";
import {Header} from "../../components/header/header.component";
import {Message} from "../../components/message";
import {Container} from "../../components/container/container.component";

export function AccountBlockedComponent(props) {
	// const {error, updateFormData, expirationDate} = props;
	// const classes = useStyles({});

	/* eslint-disable */
	let history = useHistory();
	let location: any = useLocation();

	let { from } = location.state || { from: { pathname: "/" } };
	/* eslint-enable */

	return (
		<Container>
			<Header>
				Twoje konto zostało zablokowane
			</Header>
			<Message>
				Twoje konto zostało zablokowane ponieważ naruszyłeś postanowienia regulaminu.
				<br /><br />
				W razie pytań lub wątpliwości prosimy o kontakt.
			</Message>
		</Container>
	);
}



