import React from "react";
//import {useStyles} from "./settings.styles";
import {Header} from "../../components/header/header.component";
import {Container} from "../../components/container/container.component";
import {TokensSection} from "./parts/tokens";
import {PasswordSection} from "./parts/password";
import {InvoiceSection} from "./parts/invoice";
import {RegistrationNumberSection} from "./parts/registrationNumber";
import {Accordion} from "../../components/accordion";
import {useHistory, useLocation} from "react-router-dom";

export function SettingsComponent(props) {
	//const {error, updateFormData} = props;
	//const classes = useStyles({});

	/* eslint-disable */
	let history = useHistory();
	let location: any = useLocation();

	let { from } = location.state || { from: { pathname: "/" } };
	/* eslint-enable */

	return (
		<Container>
			<Header>
				Ustawienia
			</Header>
			<Accordion title="Tokeny autoryzacyjne BDO">
				<TokensSection />
			</Accordion>
			<Accordion title="Numer rejestracyjny">
				<RegistrationNumberSection />
			</Accordion>
			<Accordion title="Zmiana hasła">
				<PasswordSection />
			</Accordion>
			{false && <Accordion title="Dane do faktury">
				<InvoiceSection />
			</Accordion>}
		</Container>
	);
}



