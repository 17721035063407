import * as React from "react";
import {useStyles} from "./loader.styles";
import classnames from "classnames";

export function Loader(props) {
	const {show} = props;

	const classes = useStyles({});

	return (
		<>
			<div className={classnames(classes.curtain, {[classes.show]: show})}>
				<div className={classes.msg}>Trwa ładowanie...</div>
			</div>
			<div className={classnames(classes.curtain, classes.modal, {[classes.show]: show})}></div>
		</>
	);
}

