import {
	TYPE_CONFIRM_RECEPTION,
	TYPE_CONFIRM_RECEPTION_FAILURE,
	TYPE_CONFIRM_RECEPTION_SUCCESS,
	TYPE_FETCH_CONFIRMATION_GENERATED_CARDS,
	TYPE_FETCH_CONFIRMATION_GENERATED_CARDS_FAILURE,
	TYPE_FETCH_CONFIRMATION_GENERATED_CARDS_SUCCESS,
	TYPE_REJECT_RECEPTION,
	TYPE_REJECT_RECEPTION_FAILURE,
	TYPE_REJECT_RECEPTION_SUCCESS,
	TYPE_GET_CARD_DETAILS,
	TYPE_GET_CARD_DETAILS_FAILURE,
	TYPE_GET_CARD_DETAILS_SUCCESS,
} from "../actions/types";
import {
	TYPE_CHANGE_API_TYPE,
	TYPE_CHANGE_PASSWORD,
	TYPE_CHANGE_PASSWORD_FAILURE,
	TYPE_CHANGE_PASSWORD_SUCCESS,
	TYPE_CLEAR_LOCATION_TO_CHANGE_TO,
	TYPE_CLEAR_REFRESH_CURRENT_PAGE,
	TYPE_GET_USER,
	TYPE_GET_USER_FAILURE,
	TYPE_GET_USER_SUCCESS,
	TYPE_LOGIN,
	TYPE_LOGIN_FAILURE,
	TYPE_LOGIN_SUCCESS,
	TYPE_LOGOUT,
	TYPE_REGISTER,
	TYPE_REGISTER_FAILURE,
	TYPE_REGISTER_SUCCESS,
	TYPE_UPDATE_BDO_CREDENTIALS,
	TYPE_UPDATE_BDO_CREDENTIALS_FAILURE,
	TYPE_UPDATE_BDO_CREDENTIALS_SUCCESS,
	TYPE_UPDATE_FORM_DATA,
} from "../../../../store/actions/types";
import {ICard} from "../../../../interfaces/getApprovedCardsResponse";
import {IAccessToken} from "../../../../interfaces/accessToken";
import {getClearLocationToChangeToReducer} from "../../../../store/reducers/clearLocationToChangeTo";
import {getClearRefreshCurrentPageReducer} from "../../../../store/reducers/clearRefreshCurrentPage";
import {getUpdateFormDataReducer} from "../../../../store/reducers/updateFormData";
import {getDefaultActionReducer} from "../../../../store/reducers/defaultAction";
import {getLoginSuccessReducer} from "../../../../store/reducers/loginSuccess";
import {getDefaultFailureActionReducer} from "../../../../store/reducers/defaultFailureAction";
import {getLoginFailureReducer} from "../../../../store/reducers/loginFailure";
import {getLogoutReducer} from "../../../../store/reducers/logout";
import {getGetUserReducer} from "../../../../store/reducers/getUser";
import {getGetUserSuccessReducer} from "../../../../store/reducers/getUserSuccess";
import {getGetUserFailureReducer} from "../../../../store/reducers/getUserFailure";
import {getRegisterSuccessReducer} from "../../../../store/reducers/registerSuccess";
import {getFetchConfirmationGeneratedCardsSuccessReducer} from "../../../../store/reducers/fetchConfirmationGeneratedCardsSuccess";
import {getConfirmReceptionSuccessReducer} from "../../../../store/reducers/confirmReceptionSuccess";
import {getRejectReceptionSuccessReducer} from "../../../../store/reducers/rejectReceptionSuccess";
import {getChangePasswordSuccessReducer} from "../../../../store/reducers/changePasswordSuccess";
import {IAccountData} from "../../../../interfaces/accountData";
import {getUpdateBDOCredentialsSuccessReducer} from "../../../../store/reducers/updateBDOCredentialsSuccess";
import {getChangeApiTypeReducer} from "../../../../store/reducers/changeApiType";
import {getCardDetailsSuccessReducer, getCardDetailsReducer, getCardDetailsFailureReducer} from "./getCardDetails";
import {ApiTypes} from "../../../../constants/apiTypes";


export interface IAppState {
	isDisconnected: boolean;
	isLoggedIn: boolean;
	isLoading: boolean;
	isBlocked: boolean;
	isExpired: boolean;
	hasBDOTokens: boolean | null;
	hasInvalidBDOTokens: boolean;
	accessToken: IAccessToken | null,
	accountData: IAccountData | null,
	isFetchingAccountData: boolean,
	isFetchingCardDetails: any;
	locationToChangeTo: string | null;
	refreshCurrentPageStatus: boolean | null;
	error: any | null;

	cardsDetails: any,
	confirmationGeneratedCards: ICard[] | null;
	apiType: ApiTypes.KPO | ApiTypes.KPOK,

	exchangedData: any;
}

const defaultState: IAppState = {
	isDisconnected: false,
	isLoggedIn: false,
	isLoading: false,
	isBlocked: false,
	isExpired: false,
	hasBDOTokens: null,
	hasInvalidBDOTokens: false,
	accessToken: null,
	accountData: null,
	isFetchingAccountData: false,
	isFetchingCardDetails: {},
	locationToChangeTo: null, //pomaga obejsc problem z polaczeniem Redux i ReactRouter (ma tylko triggerowac zmiane urla, nie wskazuje na poprawny aktualny adres)
	refreshCurrentPageStatus: null,
	error: null,

	cardsDetails: {},
	confirmationGeneratedCards: null,
	exchangedData: {},
	apiType: ApiTypes.KPO,
};

export function appReducers(state = defaultState, action) {
	switch (action.type) {
		case TYPE_CLEAR_LOCATION_TO_CHANGE_TO:
			return getClearLocationToChangeToReducer(state, action);
		case TYPE_CLEAR_REFRESH_CURRENT_PAGE:
			return getClearRefreshCurrentPageReducer(state, action);
		case TYPE_UPDATE_FORM_DATA:
			return getUpdateFormDataReducer(state, action);
		case TYPE_UPDATE_BDO_CREDENTIALS:
			return getDefaultActionReducer(state, action);
		case TYPE_UPDATE_BDO_CREDENTIALS_SUCCESS:
			return getUpdateBDOCredentialsSuccessReducer(state, action);
		case TYPE_UPDATE_BDO_CREDENTIALS_FAILURE:
			return getDefaultFailureActionReducer(state, action);
		case TYPE_LOGIN:
			return getDefaultActionReducer(state, action);
		case TYPE_LOGIN_SUCCESS:
			return getLoginSuccessReducer(state, action);
		case TYPE_LOGIN_FAILURE:
			return getLoginFailureReducer(state, action);
		case TYPE_GET_USER:
			return getGetUserReducer(state, action);
		case TYPE_GET_USER_SUCCESS:
			return getGetUserSuccessReducer(state, action);
		case TYPE_GET_USER_FAILURE:
			return getGetUserFailureReducer(state, action);
		case TYPE_REGISTER:
			return getDefaultActionReducer(state, action);
		case TYPE_REGISTER_SUCCESS:
			return getRegisterSuccessReducer(state, action);
		case TYPE_REGISTER_FAILURE:
			return getDefaultFailureActionReducer(state, action);
		case TYPE_LOGOUT:
			return getLogoutReducer(state, action);
		case TYPE_CHANGE_PASSWORD:
			return getDefaultActionReducer(state, action);
		case TYPE_CHANGE_PASSWORD_SUCCESS:
			return getChangePasswordSuccessReducer(state, action);
		case TYPE_CHANGE_PASSWORD_FAILURE:
			return getDefaultFailureActionReducer(state, action);

		case TYPE_FETCH_CONFIRMATION_GENERATED_CARDS:
			return getDefaultActionReducer(state, action);
		case TYPE_FETCH_CONFIRMATION_GENERATED_CARDS_SUCCESS:
			return getFetchConfirmationGeneratedCardsSuccessReducer(state, action);
		case TYPE_FETCH_CONFIRMATION_GENERATED_CARDS_FAILURE:
			return getDefaultFailureActionReducer(state, action);
		case TYPE_CONFIRM_RECEPTION:
			return getDefaultActionReducer(state, action);
		case TYPE_CONFIRM_RECEPTION_SUCCESS:
			return getConfirmReceptionSuccessReducer(state, action);
		case TYPE_CONFIRM_RECEPTION_FAILURE:
			return getDefaultFailureActionReducer(state, action);
		case TYPE_REJECT_RECEPTION:
			return getDefaultActionReducer(state, action);
		case TYPE_REJECT_RECEPTION_SUCCESS:
			return getRejectReceptionSuccessReducer(state, action);
		case TYPE_REJECT_RECEPTION_FAILURE:
			return getDefaultFailureActionReducer(state, action);
		case TYPE_CHANGE_API_TYPE:
			return getChangeApiTypeReducer(state, action);
		case TYPE_GET_CARD_DETAILS:
			return getCardDetailsReducer(state, action);
		case TYPE_GET_CARD_DETAILS_SUCCESS:
			return getCardDetailsSuccessReducer(state, action);
		case TYPE_GET_CARD_DETAILS_FAILURE:
			return getCardDetailsFailureReducer(state, action);
		default:
			return state;
	}
}
