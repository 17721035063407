import React, {useEffect} from "react";
import {Form} from "react-final-form";
import {Textbox} from "../../../../components/textbox/textbox";
import {Button} from "../../../../components/button/button";
import {ErrorMessage} from "../../../../components/errorMessage";
import {ISettingsPasswordFormValues} from "../../../../interfaces/forms/settingsPassword";
import {useStyles} from "./password.styles";
import {globalStyles} from "../../../../css/global.styles";
import {FormFieldWrapper} from "../../../../components/formFieldWrapper/formFieldWrapper.component";
import {containsSpaces, isValidPassword} from "../../../../helpers/validation";
import {useHistory, useLocation} from "react-router-dom";
import {getUserId} from "../../../../helpers/user";
// import { useForm } from 'react-final-form' @todo

export function PasswordComponent(props) {
	const {error, changePassword, accessToken, refreshCurrentPageStatus, clearRefreshCurrentPage} = props;
	const classes = useStyles({});
	const globalClasses = globalStyles({});
	// const [x, setX] = useState("");
	// let form; // @todo

	// form = useForm<ISettingsPasswordFormValues>("test"); // @todo

	/* eslint-disable */
	let history = useHistory();
	let location: any = useLocation();

	let { from } = location.state || { from: { pathname: "/" } };
	/* eslint-enable */

	useEffect(() => {
		if (refreshCurrentPageStatus !== null) {
			clearRefreshCurrentPage();
			// setX("hyh");

			// form.reset(); // @todo
		}
	}, [refreshCurrentPageStatus]);

	const initialValues: ISettingsPasswordFormValues = {
		oldPassword: "",
		password: "",
		confirmedPassword: "",
	};

	const onSubmit = async (values: ISettingsPasswordFormValues) => {
		const userId = getUserId(accessToken);
		changePassword(userId, values);
	};

	const handleTokensValidation = (values: ISettingsPasswordFormValues) => {
		const errors: any = {};

		if (!values.oldPassword) {
			errors.oldPassword = "To pole jest wymagane";
		}

		if (containsSpaces(values.oldPassword)) {
			errors.oldPassword = "Aktualne hasło nie może zawierac spacji";
		}

		if (!values.password) {
			errors.password = "To pole jest wymagane";
		}

		if (!isValidPassword(values.password)) {
			errors.password = "Hasło powinno zawierać od 5 do 20 znaków (cyfry od 0-9 lub litery a-z)";
		}

		if (containsSpaces(values.password)) {
			errors.password = "Hasło nie może zawierac spacji";
		}

		if (!values.confirmedPassword) {
			errors.confirmedPassword = "To pole jest wymagane";
		}

		if (containsSpaces(values.confirmedPassword)) {
			errors.confirmedPassword = "Hasło nie może zawierac spacji";
		}

		if (values.password !== values.confirmedPassword) {
			errors.confirmedPassword = "Hasła muszą być identyczne";
		}

		return errors;
	};

	return (
		<>
			<Form
				validate={handleTokensValidation}
				onSubmit={onSubmit}
				initialValues={initialValues}
				render={({ handleSubmit, form, submitting, pristine, values }) => {
					return (
						<form onSubmit={handleSubmit} className={classes.form}>
							<FormFieldWrapper>
								<Textbox
									name="oldPassword"
									label="Aktualne hasło"
									className={globalClasses.inputWidth}
									serverErrors={error}
								/>
							</FormFieldWrapper>
							<FormFieldWrapper>
								<Textbox
									name="password"
									label="Nowe hasło"
									className={globalClasses.inputWidth}
									serverErrors={error}
								/>
							</FormFieldWrapper>
							<FormFieldWrapper>
								<Textbox
									name="confirmedPassword"
									label="Powtórz nowe hasło"
									className={globalClasses.inputWidth}
									serverErrors={error}
								/>
							</FormFieldWrapper>
							<FormFieldWrapper alignContent="right">
								<Button variant="contained" color="primary" type="submit" disabled={Boolean(Object.keys(handleTokensValidation(values)).length)}>Zapisz zmiany</Button>
							</FormFieldWrapper>

							<ErrorMessage errors={error} type="general" />
						</form>
					);
				}}
			/>
		</>
	);
}



