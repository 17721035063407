import {updateBDOCredentials as updateBDOCredentialsAPI} from "../../api/api";
import {TYPE_UPDATE_BDO_CREDENTIALS, TYPE_UPDATE_BDO_CREDENTIALS_SUCCESS, TYPE_UPDATE_BDO_CREDENTIALS_FAILURE} from "./types";
import {ISettingsTokensFormValues} from "../../interfaces/forms/settingsTokens";
import {toast} from "react-toastify";
import {getConfiguration} from "../../helpers/toast";

export function updateBDOCredentials(userId, formValues: ISettingsTokensFormValues) {
	return function(dispatch) {
		dispatch(updateBDOCredentialsAction());

		return updateBDOCredentialsAPI(userId, formValues).then((response) => {
			dispatch(updateBDOCredentialsSuccessAction(response));
		}).catch((error) => {
			dispatch(updateBDOCredentialsFailureAction(error));
		});
	};
}

export function updateBDOCredentialsAction() {
	return {
		type: TYPE_UPDATE_BDO_CREDENTIALS,
		isLoading: true,
	};
}

export function updateBDOCredentialsSuccessAction(response) {
	toast.success("Tokeny BDO zostały zaktualizowane", getConfiguration());

	return {
		type: TYPE_UPDATE_BDO_CREDENTIALS_SUCCESS,
		isLoading: false,
		response: response,
	};
}

export function updateBDOCredentialsFailureAction(error) {
	return {
		type: TYPE_UPDATE_BDO_CREDENTIALS_FAILURE,
		isLoading: false,
		error,
	};
}
